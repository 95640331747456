import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

/* import styling */
import '../../styles/datepicker.css'

// import actions
// import { createMatch } from '../../actions/actions.js'

/* import utils */
import { toDbDate } from '../../utils/dateAndTime.js'

/* import components */
import Picker from './Picker.js'
import SubmitButton from './SubmitButton.js'

class SignUpForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formErrorMessage: null,
      submitButtonEnabled: true,
      selectedLabel: '',
      formData: {
        first_name: '',
        last_name: '',
        gender: '',
        dob: '',
        email: '',
        password: '',
        retype_password: ''
      }
    }
    this.genderOptions = [
      {text: 'female', value: 'female'},
      {text: 'male', value: 'male'},
      {text: 'other', value: 'other'}
    ]
  }


  handleFirstNameInputChange = (event) => {
    let formData = this.state.formData
    formData.first_name = event.target.value
    this.setState({formData: formData})
    this.setState({formErrorMessage: null})
  }


  handleLastNameInputChange = (event) => {
    let formData = this.state.formData
    formData.last_name = event.target.value
    this.setState({formData: formData})
    this.setState({formErrorMessage: null})
  }


  handleGenderSelection = (option) => {
    let formData = this.state.formData
    formData.gender = option.value
    this.setState({formData: formData})
    this.setState({selectedLabel: ''})
    this.setState({formErrorMessage: null})
  }


  handleDobDateSelection = (date) => {
    let formData = this.state.formData
    let dateString = toDbDate(date)
    formData.dob = dateString
    this.setState({formData: formData})
    this.setState({formErrorMessage: null})
  }


  handlePickerClick = (label) => {
    if (this.state.selectedLabel == label) {
      this.setState({selectedLabel: ''})
      this.setState({formErrorMessage: null})
    }
    else {
      this.setState({selectedLabel: label})
      this.setState({formErrorMessage: null})
    }
  }


  handleEmailInputChange = (event) => {
    let formData = this.state.formData
    formData.email = event.target.value
    this.setState({formData: formData})
    this.setState({formErrorMessage: null})
  }


  handlePasswordInputChange = (event) => {
    let formData = this.state.formData
    formData.password = event.target.value
    this.setState({formData: formData})
    this.setState({formErrorMessage: null})
  }


  handleRetypePasswordInputChange = (event) => {
    let formData = this.state.formData
    formData.retype_password = event.target.value
    this.setState({formData: formData})
    this.setState({formErrorMessage: null})
  }


  validateFirstNameInput = () => {
    let valid = true

    if (this.state.formData.first_name == '') {
      this.setState({formErrorMessage: 'enter first name'})
      valid = false
    }

    return valid;
  }


  validateLastNameInput = () => {
    let valid = true

    if (this.state.formData.last_name == '') {
      this.setState({formErrorMessage: 'enter last name'})
      valid = false
    }

    return valid;
  }


  validateGenderSelection = () => {
    let valid = true

    if (this.state.formData.gender == '') {
      this.setState({formErrorMessage: 'select gender'})
      valid = false
    }

    return valid;
  }


  validateDobSelection = () => {
    let valid = true

    if (this.state.formData.dob == '') {
      this.setState({formErrorMessage: 'select date of birth'})
      valid = false
    }

    return valid;
  }


  validateEmailInput = () => {
    let valid = true

    var tester = /^[^@\s]+@[^@\s]+\.[^@\s]+$/
    var validEmail = tester.test(this.state.formData.email)

    if (!validEmail) {
      this.setState({formErrorMessage: 'enter valid email address'})
      valid = false
    }

    return valid;
  }


  validatePasswordInput = () => {
    let valid = true

    if (this.state.formData.password.length < 6) {
      this.setState({formErrorMessage: 'password must be at least 6 characters'})
      valid = false 
    }
    else if (this.state.formData.retype_password == '') {
      this.setState({formErrorMessage: 'retype password'})
      valid = false
    }
    else if (this.state.formData.password != this.state.formData.retype_password) {
      this.setState({formErrorMessage: "passwords don't match"})
      valid = false 
    }

    return valid;
  }


  validateFormData = () => {
    let valid = true

    //validate first name
    let firstNameValid = this.validateFirstNameInput()

    if (!firstNameValid) {
      valid = false
      return valid;
    }

    //validate last name
    let lastNameValid = this.validateLastNameInput()

    if (!lastNameValid) {
      valid = false
      return valid;
    }

    //validate gender
    let genderValid = this.validateGenderSelection()

    if (!genderValid) {
      valid = false
      return valid;
    }

    //validate date of birth
    let dobValid = this.validateDobSelection()

    if (!dobValid) {
      valid = false
      return valid;
    }

    //validate email
    let emailValid = this.validateEmailInput()

    if (!emailValid) {
      valid = false
      return valid;
    }

    //validate password
    let passwordValid = this.validatePasswordInput()

    if (!passwordValid) {
      valid = false
      return valid;
    }

    return valid;
  }


  handleSumbit = async () => {
    this.setState({submitButtonEnabled: false})

    // validate form data
    let formDataValid = this.validateFormData()

    if (!formDataValid) {
      this.setState({submitButtonEnabled: true})
    }
    else {
      try {
        //sign up user
        let response = await this.props.signUpFunction(this.state.formData.email, this.state.formData.password)
        let userId = response.user._delegate.uid
        await this.props.addUserDataFunction(userId, this.state.formData)
        this.props.navigate("/")
      }
      catch (error) {
        console.error(error)
        this.setState({formErrorMessage: 'Sign up failed'})
        this.setState({submitButtonEnabled: true})
      }
    }
  }

  
  render() {
    return (
      <div className="form authform">

        <div className="form-title-header">
            Sign Up
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              First name:
          </div>
          <div className="form-field-container">
            <input
              className="_input"
              name="firstName"
              type="text"
              value={this.state.formData.first_name}
              onChange={this.handleFirstNameInputChange} 
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Last name:
          </div>
          <div className="form-field-container">
            <input
              className="_input"
              name="lastName"
              type="text"
              value={this.state.formData.last_name}
              onChange={this.handleLastNameInputChange} 
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Gender:
          </div>
          <div className="form-field-container">
            <Picker
              label={'gender'}
              default={''}
              value={this.state.formData.gender}
              options={this.genderOptions}
              selectedLabel={this.state.selectedLabel}
              handlePickerClick={this.handlePickerClick}
              handleOptionClick={this.handleGenderSelection}
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Date of birth:
          </div>
          <div className="form-field-container">
            <DatePicker
              className={this.state.formData.dob == '' ? "picker date-picker unconfirmed-picker-value" : "picker date-picker confirmed-picker-value"}
              dateFormat="dd/MM/yyyy"
              selected={this.state.formData.dob == '' ? new Date('1994-01-01') : new Date(this.state.formData.dob)}
              onChange={(date) => this.handleDobDateSelection(date)}
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Email:
          </div>
          <div className="form-field-container">
            <input
              className="_input"
              name="email"
              type="text"
              value={this.state.formData.email}
              onChange={this.handleEmailInputChange} 
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Password:
          </div>
          <div className="form-field-container">
            <input
              className="_input"
              name="password"
              type="password"
              value={this.state.formData.password}
              onChange={this.handlePasswordInputChange} 
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Retype Password:
          </div>
          <div className="form-field-container">
            <input
              className="_input"
              name="retypePassword"
              type="password"
              value={this.state.formData.retype_password}
              onChange={this.handleRetypePasswordInputChange} 
            />
          </div>
        </div>

        <div className="form-error-message-container">
          {this.state.formErrorMessage && this.state.formErrorMessage}
        </div>

        <div className="form-button-container">
          <SubmitButton
            buttonText={'Sign Up'}
            className={'_button auth-button'}
            disabled={!this.state.submitButtonEnabled}
            handleSubmit={this.handleSumbit}
            handleDisabledSubmit={() => {console.log('button disbled')}}
          />
        </div>

        <div className="form-footer">
        </div>
        
      </div>
    );
  }
}

export default SignUpForm;

SignUpForm.propTypes = {
  signUpFunction: PropTypes.func.isRequired,
  addUserDataFunction: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired
}