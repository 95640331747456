import React, { useEffect } from 'react';

const AppStoreRedirect = () => {
  // URLs to redirect to - replace these with your actual URLs
  const ANDROID_URL = 'https://play.google.com/store/apps/details?id=com.flipflap';
  const OTHER_URL = 'https://apps.apple.com/gb/app/flipflap/id1488258038';

  useEffect(() => {
    const detectAndRedirect = () => {
      // Get user agent string
      const userAgent = navigator.userAgent.toLowerCase();
      
      // Check if the device is running Android
      const isAndroid = /android/.test(userAgent);
      
      // Perform redirect based on device type
      if (isAndroid) {
        window.location.href = ANDROID_URL;
      } else {
        window.location.href = OTHER_URL;
      }
    };

    // Add a small delay to ensure the component is mounted
    const timeoutId = setTimeout(detectAndRedirect, 100);

    // Cleanup timeout on unmount
    return () => clearTimeout(timeoutId);
  }, []);

  // Show loading state while redirect is happening
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center">
        <h1 className="text-xl font-semibold text-gray-800 mb-2">
          Redirecting...
        </h1>
        <p className="text-gray-600">
          Please wait while we direct you to the appropriate destination.
        </p>
      </div>
    </div>
  );
};

export default AppStoreRedirect;