import React from 'react'
import PropTypes from 'prop-types'

class LeagueCard extends React.Component {
  constructor(props) {
    super(props)
    this.logo = require(`../../assets/league_orgs/${this.props.league.league_org_logo_file}`)
  }

  render() {
    return (
      <div key={this.props.league.id} className="_card" onClick={() => {this.props.navigate(`/league-orgs/${this.props.league.league_org_id}/leagues/${this.props.league.id}`)}}>
        <div className="card-image-container">
          <img src={this.logo}/>
        </div>
        <div className="card-details-container">
          <div className="card-title-detail-container">
            <p>{this.props.league.league_org_name}</p>
          </div>
          <div className="card-detail-container">
            <p>{this.props.league.name}</p>
          </div>
          <div className="card-detail-container">
            <p>{this.props.league.venue.name}</p>
          </div>
          <div className="card-detail-container">
            <p>{this.props.league.player_group} • {this.props.league.format}</p>
          </div>
        </div>
      </div>
    );
  }
}

LeagueCard.propTypes = {
  league: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}

export default LeagueCard;