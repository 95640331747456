// import core objects
import React, { useState, useEffect } from 'react'
import { Alert, Spinner } from 'react-bootstrap'

// import auth context
//import { useAuth } from '../../contexts/AuthContext'

// import navigation functions
import { useNavigate, useParams } from "react-router-dom"

// import actions
import { getLeague } from '../../actions/actions.js'

// import styles
import '../../styles/main.css'
import '../../styles/nav.css'
import '../../styles/components.css'

// import components
import Navbar from '../components/Navbar'
import Breadcrumbs from '../components/Breadcrumbs'
import SeasonCard from '../components/SeasonCard'


export default function LeaguePage() {
  const [loadingState, setLoadingState] = useState(true)
  const [errorState, setErrorState] = useState(false)
  const [leagueState, setLeagueState] = useState(undefined)
  const { leagueOrgId, leagueId } = useParams()
  let navigate = useNavigate()
  const [crumbs, setCrumbs] = useState([])


  useEffect(() => {
    // get league
    getLeague(leagueOrgId, leagueId).then(league => {
      setLeagueState(league)
      setCrumbs([
        {crumb: 'leagues', to: '/'},
        {crumb: `${league.league_org_name}`, to: `/league-orgs`},
        {crumb: `${league.name}`, to: `/league-orgs/${leagueOrgId}/leagues/${leagueId}`}
      ])
    }).catch(
      () => setErrorState('Failed to fetch league.')
    ).finally(
      () => setLoadingState(false)
    )
  }, [])


  let mapSeasons = (seasons) => {
    return seasons.map((season) => {
      return (
        <SeasonCard key={season.id} season={season} navigate={navigate}/>
      );
    });
  }


  let displaySeasons = (league) => {
    // show spinner if loading
    if (loadingState) {
      return (
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    }

    // display text if no results
    if (league == undefined || league?.seasons == undefined) {
      return (
        <div className="card-container">
          <div className="no-results-container">
            No seasons to display.
          </div>
        </div>
      );
    }

    // else map results
    let seasonsArray = []
    for (let seasonId of Object.keys(league.seasons)) {
      let season = {
        'id': seasonId,
        'league': league,
        ...league.seasons[seasonId]
      }
      seasonsArray.push(season)
    }

    return (
      <div className="card-container">
        {mapSeasons(seasonsArray)}
      </div>
    );
  }


  return (
    <div className="mainContainer">
      <Navbar/>
      
      <div className="page-title-container">
        {leagueState && leagueState.name}
      </div>

      <Breadcrumbs crumbs={crumbs}/>

      <div className="page-subtitle-container">
        Seasons
      </div>

      {errorState && <Alert variant="danger">{errorState}</Alert>}
      
      {displaySeasons(leagueState)}

      <div className="page-bottom-container">
      </div>

    </div>
  );
}