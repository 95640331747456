import React from 'react'
import PropTypes from 'prop-types'


let mapOptions = (options, timepart, handleOptionClick) => {
  return options.map((option, i) => {
    return (
      <div key={i} className="picker-option" onClick={() => {handleOptionClick(option, timepart)}}>
        {option.text}
      </div>
    );
  });
}


let displayOptions = (options, timepart, handleOptionClick) => {
  return (
    <div className="picker-options-container">
      {mapOptions(options, timepart, handleOptionClick)}
    </div>
  );
}


export default function TimePicker(props) {
  let hourSelected = props.selectedLabel == `${props.label}.hour`
  let pickerClassNameHours = hourSelected ? 'picker time-picker selected-picker' : 'picker time-picker'
  let hourValueConfirmed = props.valueHour != ''
  let valueClassNameHours = hourValueConfirmed ? 'picker-value confirmed-picker-value' : 'picker-value unconfirmed-picker-value'
  valueClassNameHours = hourSelected ? `${valueClassNameHours} selected-picker-value` : valueClassNameHours
  let displayvalueHour = hourValueConfirmed ? props.valueHour : props.defaultHour

  let minuteSelected = props.selectedLabel == `${props.label}.minute`
  let pickerClassNameMins = minuteSelected ? 'picker time-picker selected-picker' : 'picker time-picker'
  let minuteValueConfirmed = props.valueMinute != ''
  let valueClassNameMins = minuteValueConfirmed ? 'picker-value confirmed-picker-value' : 'picker-value unconfirmed-picker-value'
  valueClassNameMins = minuteSelected ? `${valueClassNameMins} selected-picker-value` : valueClassNameMins
  let displayvalueMinute = minuteValueConfirmed ? props.valueMinute : props.defaultMinute
  

  return (
    <div className="form-field-container">
      <div className={pickerClassNameHours}>
        <div className={valueClassNameHours} onClick={() => {props.handlePickerClick(`${props.label}.hour`)}}>
          {displayvalueHour}
        </div>
        {hourSelected && displayOptions(props.options.hours, 'hour', props.handleOptionClick)}
      </div>

      <div className="time-picker-divider-div">
        :
      </div>

      <div className={pickerClassNameMins}>
        <div className={valueClassNameMins} onClick={() => {props.handlePickerClick(`${props.label}.minute`)}}>
          {displayvalueMinute}
        </div>
        {minuteSelected && displayOptions(props.options.mins, 'minute', props.handleOptionClick)}
      </div>
    </div>
  );
}

TimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  defaultHour: PropTypes.string.isRequired,
  defaultMinute: PropTypes.string.isRequired,
  valueHour: PropTypes.string.isRequired,
  valueMinute: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,       
  selectedLabel: PropTypes.string.isRequired,
  handlePickerClick: PropTypes.func.isRequired,
  handleOptionClick: PropTypes.func.isRequired
}