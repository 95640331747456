import React from 'react'
import PropTypes from 'prop-types'

// import utility functions
import { formatDbDate } from '../../utils/dateAndTime.js'

class SeasonInfoCard extends React.Component {
  constructor(props) {
    super(props)
    this.logo = require(`../../assets/league_orgs/${this.props.season.league_org.logo_file}`)
    this.num_teams = this.props.season?.teams == undefined? 0 : Object.keys(this.props.season.teams).length
    this.teamsDetail = this.props.season?.max_teams == undefined ? `${this.num_teams} teams` : `${this.num_teams} / ${this.props.season.max_teams} teams`
  }

  render() {
    return (
      <div key={this.props.season.id} className="_card" >
        <div className="card-image-container">
          <img src={this.logo}/>
        </div>
        <div className="card-details-container">
          <div className="card-title-detail-container">
            <p>{this.props.season.league_org.name}</p>
          </div>
          <div className="card-detail-container">
            <p>{this.props.season.league.name}</p>
          </div>
          <div className="card-detail-container">
            <p>{this.props.season.venue.name}</p>
          </div>
          <div className="card-detail-container">
            <p>{this.props.season.league.player_group} • {this.props.season.league.format}  • {this.teamsDetail}</p>
          </div>
          <div className="card-detail-container">
            <p>{formatDbDate(this.props.season.start_date)} - {formatDbDate(this.props.season.end_date)}</p>
          </div>
        </div>
      </div>
    );
  }
}

SeasonInfoCard.propTypes = {
  season: PropTypes.object.isRequired
}

export default SeasonInfoCard;