// import core objects
import React from 'react'

// import navigation functions
import { Link, useNavigate } from "react-router-dom"

// import auth context
import { useAuth } from '../../contexts/AuthContext'

// import actions
import { addUserData } from '../../actions/actions'

// import styles
import '../../styles/main.css'
import '../../styles/auth.css'

// import components
import SignUpForm from '../components/SignUpForm.js'


export default function SignUpPage() {
  let navigate = useNavigate()

  return (
    <div className="mainContainer">

      <div className="page-title-container">
      </div>

      <div className="form-container">
        <SignUpForm
          signUpFunction={useAuth().signup}
          addUserDataFunction={addUserData}
          navigate={navigate}
        />
      </div>

      <div className="authSwitchText">
            Already have an account? <Link to="/log-in" style={{paddingLeft: '5px'}}>Log In</Link>
      </div>

      <div className="page-bottom-container">
      </div>

    </div>
  );
}