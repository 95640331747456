// import core objects
import React from 'react'
import { NavLink } from 'react-router-dom'

// import images
import flipflapLogo from '../../assets/flipflap/flipflaprefereelogo_appstore_rounded.png';

// import components
import LogoutButton from './LogoutButton'

export default function Navbar() {
  return (
    <nav className="navbar">
      <div style={{height: '100%', width: '10%', display: 'flex', alignItems: 'start', paddingLeft: 10}}>
        <div style={{height: '100%', width: 60, alignItems: 'start'}}>
          <img src={flipflapLogo}/>
        </div>
      </div>
      <div style={{height: '100%', width: '20%', textAlign: 'center'}}>
        < NavLink to="/" className={({ isActive }) => isActive ? "active-nav-link" : "inactive-nav-link"}>
          Leagues
        </NavLink>
      </div>
      <div style={{height: '100%', flex: 1, textAlign: 'center'}}>
        <NavLink to="/league-orgs" className={({ isActive }) => isActive ? "active-nav-link" : "inactive-nav-link"}>
          League Orgs
        </NavLink>
      </div>
      <div style={{height: '100%',  flex: 1, textAlign: 'center'}}>
        <NavLink to="/venues" className={({ isActive }) => isActive ? "active-nav-link" : "inactive-nav-link"}>
          Venues
        </NavLink>
      </div>
      <div style={{height: '100%', width: '10%', display: 'flex', alignItems: 'end', justifyContent: 'center', paddingRight: 10}}>
        <LogoutButton/>
      </div>
    </nav>
  );
}
