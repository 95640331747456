// import core objects
import React, { useState, useEffect } from 'react'
import { Alert, Spinner } from 'react-bootstrap'

// import auth context
//import { useAuth } from '../../contexts/AuthContext'

// import navigation functions
//import { useNavigate } from "react-router-dom";

// import actions
import { getLeagueOrgs } from '../../actions/actions.js'

// import styles
import '../../styles/main.css'
import '../../styles/nav.css'
import '../../styles/components.css'

// import components
import Navbar from '../components/Navbar'
import Breadcrumbs from '../components/Breadcrumbs'
import LeagueOrgCard from '../components/LeagueOrgCard'


export default function LeagueOrgsPage() {
  const [loadingState, setLoadingState] = useState(true)
  const [errorState, setErrorState] = useState(false)
  const [leagueOrgsState, setLeagueOrgsState] = useState([])
  //const userId = useAuth().currentUser.uid
  const userId = 'b8oHsrovTHX6KoNBIkS1hdteCgu1'
  //let navigate = useNavigate()
  const crumbs = [
    {crumb: 'league-orgs', to: '/league-orgs'}
  ]


  useEffect(() => {
    // get league orgs
    getLeagueOrgs(userId).then(leagueOrgs => {
      setLeagueOrgsState(leagueOrgs)
    }).catch(
      () => setErrorState('Failed to fetch league organisations.')
    ).finally(
      () => setLoadingState(false)
    )
  }, [])


  let mapLeagueOrgs = (leagueOrgs) => {
    return leagueOrgs.map((leagueOrg) => {
      return (
        <LeagueOrgCard key={leagueOrg.id} leagueOrg={leagueOrg}/>
      );
    });
  }


  let displayLeagueOrgs = (leagueOrgs) => {
    // show spinner if loading
    if (loadingState) {
      return (
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    }

    // display text if no results
    if (leagueOrgs.length == 0) {
      return (
        <div className="card-container">
          <div className="no-results-container">
            No leagues orgs to display.
          </div>
        </div>
      );
    }

    // else map results
    return (
      <div className="card-container">
        {mapLeagueOrgs(leagueOrgs)}
      </div>
    );
  }


  return (
    <div className="mainContainer">
      <Navbar/>
      
      <div className="page-title-container">
          League Organisations
      </div>

      <Breadcrumbs crumbs={crumbs}/>

      {errorState && <Alert variant="danger">{errorState}</Alert>}
      
      {displayLeagueOrgs(leagueOrgsState)}

      <div className="page-bottom-container">
      </div>

    </div>
  );
}