import React from 'react'
import PropTypes from 'prop-types'


let mapTableRows = (rows) => {
  return rows.map((row) => {
    let crest = require(`../../assets/crests/${row.crestFile}`)

    return (
      <div key={row.rank} className="league-table-row">
        <div className="league-table-position-container">
          {row.rank}
        </div>
        <div className="league-table-team-container">
          <div className="league-table-team-crest-container">
            <img src={crest} className="team-crest"/>
          </div>
          <div className="league-table-team-name-container">
            {row.team}
          </div>
        </div>
        <div className="league-table-played-container">
          {row.played}
        </div>
        <div className="league-table-won-container">
          {row.won}
        </div>
        <div className="league-table-drawn-container">
          {row.drawn}
        </div>
        <div className="league-table-lost-container">
          {row.lost}
        </div>
        <div className="league-table-points-container">
          {row.points}
        </div>
      </div>
    );
  });
}


export default function LeagueTable(props) {
  return (
    <div className="league-table">
      <div className="league-table-header">
        <div className="league-table-position-container">
            Pos
        </div>
        <div className="league-table-team-container">
            Team
        </div>
        <div className="league-table-played-container">
            P
        </div>
        <div className="league-table-won-container">
            W
        </div>
        <div className="league-table-drawn-container">
            D
        </div>
        <div className="league-table-lost-container">
            L
        </div>
        <div className="league-table-points-container">
            Pts
        </div>
      </div>
      {mapTableRows(props.rows)}
    </div>
  );
}


LeagueTable.propTypes = {
  rows: PropTypes.array.isRequired
}