import React from 'react'
import PropTypes from 'prop-types'

class SubmitButton extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={this.props.className} tabIndex="0" onClick={() => {this.props.disabled ? this.props.handleDisabledSubmit() : this.props.handleSubmit()}}>
        {this.props.buttonText}
      </div>
    );
  }
}

export default SubmitButton;

SubmitButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDisabledSubmit: PropTypes.func.isRequired
}