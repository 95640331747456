import React from 'react'
import PropTypes from 'prop-types'


let mapOptions = (options, handleOptionClick) => {
  return options.map((option, i) => {
    return (
      <div key={i} className="picker-option" onClick={() => {handleOptionClick(option)}}>
        {option.text}
      </div>
    );
  });
}


let displayOptions = (options, handleOptionClick) => {
  return (
    <div className="picker-options-container">
      {mapOptions(options, handleOptionClick)}
    </div>
  );
}


export default function Picker(props) {
  let selected = props.selectedLabel == props.label
  let pickerClassName = selected ? 'picker selected-picker' : 'picker'
  let valueConfirmed = props.value != ''
  let valueClassName = valueConfirmed ? 'picker-value confirmed-picker-value' : 'picker-value unconfirmed-picker-value'
  valueClassName = selected ? `${valueClassName} selected-picker-value` : valueClassName
  let displayValue = valueConfirmed ? props.value : props.default
  

  return (
    <div className={pickerClassName}>
      <div className={valueClassName} onClick={() => {props.handlePickerClick(props.label)}}>
        {displayValue}
      </div>
      {selected && displayOptions(props.options, props.handleOptionClick)}
    </div>
  );
}

Picker.propTypes = {
  label: PropTypes.string.isRequired,
  default: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.array.isRequired,       
  selectedLabel: PropTypes.string.isRequired,
  handlePickerClick: PropTypes.func.isRequired,
  handleOptionClick: PropTypes.func.isRequired
}