import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

let mapCrumbs = (crumbs) => {
  return crumbs.map((crumb, index) => {
    let divLength = index != crumbs.length - 1 ? 8 * crumb.crumb.length + 18 : 8 * crumb.crumb.length

    return (
      <div key={index} className="breadcrumbs" style={{width: divLength}}>
        <Link to={crumb.to} className="breadcrumb">{crumb.crumb.toLowerCase()}</Link>{index != crumbs.length - 1 && <p className="breadcrumb-divider">{'>'}</p>}
      </div>
    );
  });
}

export default function Breadcrumbs(props) {
  return (
    <div className="breadcrumb-container">
      {mapCrumbs(props.crumbs)}
    </div>
  );
}

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired
}
