import { getTimestamp } from '../utils/dateAndTime.js'

class Match {
  constructor(matchData) {
    this.id = matchData.id
    this.data = matchData
    this.status = this.getStatus()
  }


  getStatus() {
    var matchDate = this.data.schedule.kickoff_date
    var todayDate = getTimestamp().split(' ')[0]

    let status

    if (matchDate > todayDate || matchDate == todayDate && this.data?.timings?.kicked_off_at == undefined && this.data?.timings?.abandoned_at == undefined) {
      status = 'upcoming'
    }
    else if (matchDate < todayDate || matchDate == todayDate && this.data?.timings?.full_time_at != undefined || matchDate == todayDate && this.data?.timings?.abandoned_at != undefined) {
      status = 'past'
    }
    else if (matchDate == todayDate && this.data?.timings?.kicked_off_at != undefined && this.data?.timings?.full_time_at == undefined && this.data?.timings?.abandoned_at == undefined) {
      status = 'live'
    }

    return status;
  }


  getGoals(teamId) {
    let goals = 0

    if (this.data?.events != undefined) {
      for (let eventId in this.data.events) {
        if (this.data.events[eventId].team_id == teamId & this.data.events[eventId].type == "goal") {
          goals += 1
        }
      }
    }

    return goals;
  }


  getScore() {
    let score = {
      homeTeam: {id: this.data.teams.home_team.id, goals: this.getGoals(this.data.teams.home_team.id)},
      awayTeam: {id: this.data.teams.away_team.id, goals: this.getGoals(this.data.teams.away_team.id)}
    }
    return score;
  }
}

export default Match;