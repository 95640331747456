import React from 'react'
import PropTypes from 'prop-types'

/* import components */
import SubmitButton from './SubmitButton.js'

class LogInForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formErrorMessage: null,
      submitButtonEnabled: true,
      formData: {
        email: '',
        password: ''
      }
    }
  }


  handleEmailInputChange = (event) => {
    let formData = this.state.formData
    formData.email = event.target.value
    this.setState({formData: formData})
    this.setState({formErrorMessage: null})
  }


  handlePasswordInputChange = (event) => {
    let formData = this.state.formData
    formData.password = event.target.value
    this.setState({formData: formData})
    this.setState({formErrorMessage: null})
  }


  validateEmailInput = () => {
    let valid = true

    var tester = /^[^@\s]+@[^@\s]+\.[^@\s]+$/
    var validEmail = tester.test(this.state.formData.email)

    if (!validEmail) {
      this.setState({formErrorMessage: 'enter valid email address'})
      valid = false
    }

    return valid;
  }


  validatePasswordInput = () => {
    let valid = true

    if (this.state.formData.password.length < 6) {
      this.setState({formErrorMessage: 'password must be at least 6 characters'})
      valid = false 
    }

    return valid;
  }


  validateFormData = () => {
    let valid = true

    //validate email
    let emailValid = this.validateEmailInput()

    if (!emailValid) {
      valid = false
      return valid;
    }

    //validate password
    let passwordValid = this.validatePasswordInput()

    if (!passwordValid) {
      valid = false
      return valid;
    }

    return valid;
  }


  handleSumbit = async () => {
    this.setState({submitButtonEnabled: false})

    // validate form data
    let formDataValid = this.validateFormData()

    if (!formDataValid) {
      this.setState({submitButtonEnabled: true})
    }
    else {
      try {
        await this.props.logInFunction(this.state.formData.email, this.state.formData.password)
        this.props.navigate("/")
      }
      catch (error) {
        console.error(error)
        this.setState({formErrorMessage: 'Log in failed'})
        this.setState({submitButtonEnabled: true})
      }
    }
  }

  
  render() {
    return (
      <div className="form authform">

        <div className="form-title-header">
            Log In
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Email:
          </div>
          <div className="form-field-container">
            <input
              className="_input"
              name="email"
              type="text"
              value={this.state.formData.email}
              onChange={this.handleEmailInputChange} 
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Password:
          </div>
          <div className="form-field-container">
            <input
              className="_input"
              name="password"
              type="password"
              value={this.state.formData.password}
              onChange={this.handlePasswordInputChange} 
            />
          </div>
        </div>

        <div className="form-error-message-container">
          {this.state.formErrorMessage && this.state.formErrorMessage}
        </div>

        <div className="form-button-container">
          <SubmitButton
            buttonText={'Log In'}
            className={'_button auth-button'}
            disabled={!this.state.submitButtonEnabled}
            handleSubmit={this.handleSumbit}
            handleDisabledSubmit={() => {console.log('button disbled')}}
          />
        </div>

        <div className="form-footer">
        </div>
        
      </div>
    );
  }
}

export default LogInForm;

LogInForm.propTypes = {
  logInFunction: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired
}