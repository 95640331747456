import React from 'react'
import PropTypes from 'prop-types'

// import utility functions
import { formatDbDate } from '../../utils/dateAndTime.js'

//TODO
//1. update match location to pull dynamically?
//2. update match class to know status (e.g. upcoming, live or past)
//3. dynamically vary info section of match card based on match class

class MatchCard extends React.Component {
  constructor(props) {
    super(props)
    this.score = props.match.getScore()
  }

  // resolve middle section of display
  displayMiddleSection = () => {
    let middleSection
    if (this.props.match.status == 'upcoming') {
      middleSection = (
        <div className="match-card-info-container">
          <div className="match-card-details-container">
            <div className="match-card-detail">
              {this.props.match.data.location.ground_name}
            </div>
          </div>
          <div className="match-card-middle-container">
            <div className="match-card-kickoff">
              {this.props.match.data.schedule.kickoff_time}
            </div>
          </div>
          <div className="match-card-details-container">
            <div className="match-card-detail">
              {formatDbDate(this.props.match.data.schedule.kickoff_date)}
            </div>
          </div>
        </div>
      )
    }
    else {
      middleSection = (
        <div className="match-card-info-container">
          <div className="match-card-details-container">
            <div className="match-card-detail">
              {this.props.match.data.location.ground_name}
            </div>
          </div>
          <div className="match-card-middle-container">
            <div className="match-card-score">
              {this.score.homeTeam.goals}
            </div>
            <div className="match-card-score-divider">
                  -
            </div>
            <div className="match-card-score">
              {this.score.awayTeam.goals}
            </div>
          </div>
          <div className="match-card-details-container">
            <div className="match-card-detail">
            </div>
            <div className="match-card-detail">
              {formatDbDate(this.props.match.data.schedule.kickoff_date)}
            </div>
            <div className="match-card-detail">
              {this.props.match.data.schedule.kickoff_time}
            </div>
            <div className="match-card-detail">
            </div>
          </div>
        </div>
      )
    }
    return middleSection;
  }

  render() {
    return (
      <div className="_card match-card">
        <div className="match-card-team-container">
          <div className="match-card-team-crest-container">
            <img src={this.props.crests[this.props.match.data.teams.home_team.id]} className="team-crest-large"/>
          </div>
          <div className="match-card-team-name-container">
            {this.props.match.data.teams.home_team.name}
          </div>
        </div>
        
        {this.displayMiddleSection()}

        <div className="match-card-team-container">
          <div className="match-card-team-crest-container">
            <img src={this.props.crests[this.props.match.data.teams.away_team.id]} className="team-crest-large"/>
          </div>
          <div className="match-card-team-name-container">
            {this.props.match.data.teams.away_team.name}
          </div>
        </div>
      </div>
    );
  }
}

MatchCard.propTypes = {
  match: PropTypes.object.isRequired,
  crests: PropTypes.object.isRequired
}

export default MatchCard;