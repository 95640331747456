export const formatDbDate = (yyyy_mm_dd_date_string) => {
  return `${yyyy_mm_dd_date_string.split('-')[2]}/${yyyy_mm_dd_date_string.split('-')[1]}/${yyyy_mm_dd_date_string.split('-')[0]}`;
}


export const toDbDate = (date) => {
  var yyyy = String(date.getFullYear())
  var MM = String(date.getMonth() + 1).padStart(2, '0')
  var dd = String(date.getDate()).padStart(2, '0')
  var dateString = `${yyyy}-${MM}-${dd}`
  //console.log(timestamp)

  return dateString;
}


export const getTimestamp = () => {
  var date = new Date()
  //console.log(date)
  var yyyy = String(date.getFullYear())
  var MM = String(date.getMonth() + 1).padStart(2, '0')
  var dd = String(date.getDate()).padStart(2, '0')
  var hh = String(date.getHours()).padStart(2, '0')
  var mm = String(date.getMinutes()).padStart(2, '0')
  var ss = String(date.getSeconds()).padStart(2, '0')
  var timestamp = `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`
  //console.log(timestamp)

  return timestamp;
}


export const getDate = () => {
  var date = new Date()
  //console.log(date)
  var yyyy = String(date.getFullYear())
  var MM = String(date.getMonth() + 1).padStart(2, '0')
  var dd = String(date.getDate()).padStart(2, '0')
  var dateString = `${yyyy}-${MM}-${dd}`
  //console.log(timestamp)

  return dateString;
}