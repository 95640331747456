// import core objects
import React, { useState, useEffect } from 'react'
import { Alert, Spinner } from 'react-bootstrap'

// import navigation functions
import { useParams, NavLink } from "react-router-dom"

// import actions
import { getSeasonDetailed } from '../../actions/actions.js'

// import classes
import Match from '../../classes/Match.js'

// import utility functions
import { formatDbDate } from '../../utils/dateAndTime.js'
import { createTable } from '../../utils/createTable.js'

// import styles
import '../../styles/main.css'
import '../../styles/nav.css'
import '../../styles/components.css'

// import components
import Navbar from '../components/Navbar'
import Breadcrumbs from '../components/Breadcrumbs'
import ContentSelector from '../components/ContentSelector'
import SeasonInfoCard from '../components/SeasonInfoCard.js'
import TeamBar from '../components/TeamBar.js'
import LeagueTable from '../components/LeagueTable.js'
import MatchCard from '../components/MatchCard.js'


export default function SeasonPage() {
  const [loadingState, setLoadingState] = useState(true)
  const [errorState, setErrorState] = useState(false)
  const [seasonState, setSeasonState] = useState(undefined)
  const [contentSelectionState, setContentSelectionState] = useState('Info')
  const { leagueOrgId, leagueId, seasonId } = useParams()
  // let navigate = useNavigate()
  const [crumbs, setCrumbs] = useState([])


  useEffect(() => {
    // get season
    getSeasonDetailed(leagueOrgId, leagueId, seasonId).then(season => {

      setCrumbs([
        {crumb: 'leagues', to: '/'},
        {crumb: `${season.league_org.name}`, to: `/league-orgs`},
        {crumb: `${season.league.name}`, to: `/league-orgs/${leagueOrgId}/leagues/${leagueId}`},
        {crumb: `${formatDbDate(season.start_date)} - ${formatDbDate(season.end_date)}`, to: `/league-orgs/${leagueOrgId}/leagues/${leagueId}/seasons/${seasonId}`}
      ])

      //convert match data to match classes
      let matches = []
      for (let matchData of season.games) {
        let match = new Match(matchData)
        matches.push(match)
      }

      season.games = matches

      setSeasonState(season)
    }).catch(
      () => setErrorState('Failed to fetch season.')
    ).finally(
      () => setLoadingState(false)
    )
  }, [])


  let displaySeasonInfoCard = (season) => {
    return (
      <div className="selection-container">
        <div className="edit-container">
        </div>
        <SeasonInfoCard season={season}/>
      </div>
    );
  }


  let mapTeams = (teams) => {
    return teams.map((team) => {
      return (
        <TeamBar key={team.id} team={team}/>
      );
    });
  }


  let displayTeams = (teams) => {
    // display text if no results
    if (teams.length == 0) {
      return (
        <div className="selection-container">
          <div className="edit-container">
          </div>
          <div className="no-results-container">
            No teams to display.
          </div>
        </div>
      );
    }

    // else map results
    return (
      <div className="selection-container">
        <div className="edit-container">
        </div>
        {mapTeams(teams)}
      </div>
    );
  }

  
  let displayTable = (teams, matches) => {
    let tableRows = createTable(teams, matches)

    return (
      <div className="selection-container">
        <div className="edit-container">
        </div>
        <LeagueTable rows={tableRows}/>
      </div>
    );
  }


  let mapMatches = (matches) => {
    return matches.map((match) => {
      return (
        <MatchCard key={match.match.id} match={match.match} crests={match.crests}/>
      );
    });
  }

  let sortMatches = (a, b) => {
    var stringA = a.data.schedule.kickoff_date + a.data.schedule.kickoff_time
    var stringB = b.data.schedule.kickoff_date + b.data.schedule.kickoff_time
    return stringA.localeCompare(stringB);
  }


  let displayMatches = (matches, teams) => {
    // display text if no results
    if (matches.length == 0) {
      return (
        <div className="selection-container">
          <div className="edit-container">
            <NavLink className="edit-link" to={`/league-orgs/${leagueOrgId}/leagues/${leagueId}/seasons/${seasonId}/create-match`}>
              Create
            </NavLink>
          </div>
          <div className="no-results-container">
            No matches to display.
          </div>
        </div>
      );
    }

    // else map results
    let matchesToMap = []
    matches.sort(sortMatches)
    
    for (let match of matches) {
      let homeTeamCrest
      let awayTeamCrest

      for (let team of teams) {
        if (match.data.teams.home_team.id == team.id) {
          homeTeamCrest = require(`../../assets/crests/${team.crest_file}`)
          break;
        }
      }
      for (let team of teams) {
        if (match.data.teams.away_team.id == team.id) {
          awayTeamCrest = team.crest = require(`../../assets/crests/${team.crest_file}`)
          break;
        }
      }
      let crests = {}
      crests[match.data.teams.home_team.id] = homeTeamCrest
      crests[match.data.teams.away_team.id] = awayTeamCrest
      matchesToMap.push({match: match, crests: crests})
    }

    return (
      <div className="selection-container">
        <div className="edit-container">
          <div className="edit-link-container">
            <NavLink className="edit-link" to={`/league-orgs/${leagueOrgId}/leagues/${leagueId}/seasons/${seasonId}/create-match`}>
              Create
            </NavLink>
          </div>
        </div>
        {mapMatches(matchesToMap)}
      </div>
    );
  }


  let displaySelectedContent = (season, selection) => {
    // show spinner if loading
    if (loadingState || seasonState == undefined) {
      return (
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    }

    // display page content
    switch(selection) {
    case 'Info':
      return displaySeasonInfoCard(season);

    case 'Teams':
      return displayTeams(seasonState.teams);

    case 'Table':
      return displayTable(seasonState.teams, seasonState.games);

    case 'Matches':
      return displayMatches(seasonState.games, seasonState.teams);
    }
  }


  return (
    <div className="mainContainer">
      <Navbar/>
      
      <div className="page-title-container">
        {seasonState && seasonState.league.name}
      </div>

      <Breadcrumbs crumbs={crumbs}/>

      {errorState && <Alert variant="danger">{errorState}</Alert>}

      <div className="page-subtitle-container">
        {seasonState && `${formatDbDate(seasonState.start_date)} - ${formatDbDate(seasonState.end_date)} Season`}
      </div>

      <div className="content-selector-container-container">
        <div className="content-selector-container">
          <ContentSelector selection="Info" activeSelection={contentSelectionState} setContentSelectionState={setContentSelectionState}/>
          <ContentSelector selection="Teams" activeSelection={contentSelectionState} setContentSelectionState={setContentSelectionState}/>
          <ContentSelector selection="Table" activeSelection={contentSelectionState} setContentSelectionState={setContentSelectionState}/>
          <ContentSelector selection="Matches" activeSelection={contentSelectionState} setContentSelectionState={setContentSelectionState}/>
        </div>
      </div>
      
      {displaySelectedContent(seasonState, contentSelectionState)}

      <div className="page-bottom-container">
      </div>

    </div>
  );
}