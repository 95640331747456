import React from 'react'
import PropTypes from 'prop-types'

class LeagueOrgCard extends React.Component {
  constructor(props) {
    super(props)
    this.logo = require(`../../assets/league_orgs/${this.props.leagueOrg.logo_file}`)
  }

  render() {
    return (
      <div key={this.props.leagueOrg.id} className="_card">
        <div className="card-image-container">
          <img src={this.logo}/>
        </div>
        <div className="card-details-container">
          <div className="card-title-detail-container">
            <p>{this.props.leagueOrg.name}</p>
          </div>
          <div className="card-detail-container">
          </div>
          <div className="card-detail-container">
          </div>
        </div>
      </div>
    );
  }
}

LeagueOrgCard.propTypes = {
  leagueOrg: PropTypes.object.isRequired
}

export default LeagueOrgCard;