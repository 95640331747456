import React from 'react'
import PropTypes from 'prop-types'


export default function TeamBar(props) {
  let crest = require(`../../assets/crests/${props.team.crest_file}`)

  return (
    <div className="team-bar">
      <div className="team-bar-crest-container">
        <img src={crest} className="team-crest"/>
      </div>
      <div className="team-bar-name-container">
        {props.team.team_name}
      </div>
    </div>
  );
}

TeamBar.propTypes = {
  team: PropTypes.object.isRequired
}