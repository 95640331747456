import React from 'react'
import PropTypes from 'prop-types'

export default function ContentSelector(props) {
  let handleClick = () => {
    props.setContentSelectionState(props.selection)
  }

  return (
    <div className={props.selection == props.activeSelection ? "content-selector-active" : "content-selector-inactive"} onClick={handleClick}>
      {props.selection}
    </div>
  )
}

ContentSelector.propTypes = {
  selection: PropTypes.string.isRequired,
  activeSelection: PropTypes.string.isRequired,
  setContentSelectionState: PropTypes.func.isRequired
}