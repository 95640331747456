import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

/* import styling */
import '../../styles/datepicker.css'

// import actions
import { createMatch } from '../../actions/actions.js'

/* import utils */
import { getTimestamp } from '../../utils/dateAndTime.js'
import { toDbDate } from '../../utils/dateAndTime.js'
import { sortAlphabetically } from '../../utils/strings.js'

/* import components */
import Picker from './Picker.js'
import TimePicker from './TimePicker.js'
import SubmitButton from './SubmitButton.js'

class CreateMatchForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedLabel: '',
      kickoffTimeSelection: {hour:'', minute: ''},
      formData: {
        ground_id: this.props.season.venue.id,
        ground_name: this.props.season.venue.name,
        pitch_id: '',
        pitch_name: '',
        duration: '',
        kickoff_date: '',
        kickoff_time: '',
        away_team: '',
        home_team: '',
        referee: ''
      }
    }
    this.venueOptions = [{text: this.props.season.venue.name, value: this.props.season.venue}]
    this.pitchOptions = this.getPitchOptions()
    this.durationOptions = this.getDurationOptions()
    this.teamOptions = this.getTeamOptions()
    this.refereeOptions = this.getRefereeOptions()
    this.kickoffTimeOptions = this.getKickoffTimeOptions()
  } 


  _getPitchOptions = () => {
    let options = []
    if (this.props.season.venue?.pitches != undefined) {
      for (let pitchId of Object.keys(this.props.season.venue.pitches)) {
        options.push({
          text: this.props.season.venue.pitches[pitchId].pitch_name,
          value: { id: pitchId, ...this.props.season.venue.pitches[pitchId] }
        })
      }
    }

    options.sort((a, b) => { return sortAlphabetically(a.text, b.text) })
    return options
  }
  get getPitchOptions() {
    return this._getPitchOptions
  }
  set getPitchOptions(value) {
    this._getPitchOptions = value
  }


  handlePitchSelection = (option) => {
    let formData = this.state.formData
    formData.pitch_id = option.value.id
    formData.pitch_name = option.value.pitch_name
    this.setState({formData: formData})
    this.setState({selectedLabel: ''})
  }


  getDurationOptions = () => {
    let options = []

    for (let i = 1; i <= 90; i++) {
      options.push({
        text: `${i}`,
        value:  i
      })
    }

    return options;
  }


  handleDurationSelection = (option) => {
    let formData = this.state.formData
    formData.duration = option.value
    this.setState({formData: formData})
    this.setState({selectedLabel: ''})
  }


  getTeamOptions = () => {
    let options = []

    for (let team of this.props.season.teams) {
      options.push({
        text: team.team_name,
        value: team
      })
    }

    options.sort((a,b) => {return sortAlphabetically(a.text, b.text);})
    return options;
  }


  handleHomeTeamSelection = (option) => {
    let formData = this.state.formData
    formData.home_team = {
      id: option.value.id,
      abbreviation: option.value.abbreviation,
      name: option.value.team_name
    }
    this.setState({formData: formData})
    this.setState({selectedLabel: ''})
  }


  handleAwayTeamSelection = (option) => {
    let formData = this.state.formData
    formData.away_team = {
      id: option.value.id,
      abbreviation: option.value.abbreviation,
      name: option.value.team_name
    }
    this.setState({formData: formData})
    this.setState({selectedLabel: ''})
  }


  getRefereeOptions = () => {
    let options = []
    for (let referee of this.props.season.referees) {
      options.push({
        text: `${referee.first_name} ${referee.last_name}`,
        value: referee
      })
    }

    options.sort((a,b) => {return sortAlphabetically(a.text, b.text);})
    return options;
  }


  handleRefereeSelection = (option) => {
    let formData = this.state.formData
    formData.referee = option.value
    this.setState({formData: formData})
    this.setState({selectedLabel: ''})
  }


  handleKickoffDateSelection = (date) => {
    let formData = this.state.formData
    let dateString = toDbDate(date)
    formData.kickoff_date = dateString
    this.setState({formData: formData})
  }


  getKickoffTimeOptions = () => {
    let options = {
      hours: [],
      mins: []
    }
    let hours = ['12','13','14','15','16','17','18','19','20','21','22','23','00','01','02','03','04','05','06','07','08','09','10','11']
    let mins = ['00','05','10','15','20','25','30','35','40','45','50','55']

    for (let hour of hours) {
      options.hours.push({
        text: hour,
        value:  hour
      })
    }

    for (let minute of mins) {
      options.mins.push({
        text: minute,
        value:  minute
      })
    }

    return options;
  }


  handleKickoffTimeSelection = (option, timepart) => {
    let kickoffTimeSelection = this.state.kickoffTimeSelection
    kickoffTimeSelection[timepart] = option.value
    this.setState({kickoffTimeSelection: kickoffTimeSelection})

    if (kickoffTimeSelection.hour != '' & kickoffTimeSelection.minute != '') {
      let formData = this.state.formData
      formData.kickoff_time = `${kickoffTimeSelection.hour}:${kickoffTimeSelection.minute}`
      this.setState({formData: formData})
    }

    this.setState({selectedLabel: ''})
  }


  handlePickerClick = (label) => {
    if (this.state.selectedLabel == label) {
      this.setState({selectedLabel: ''})
    }
    else {
      this.setState({selectedLabel: label})
    }
  }


  validateFormData = (formData) => {
    let valid = true

    for (let key of Object.keys(formData)) {
      if (formData[key] == '') {
        valid = false
        break;
      }
    }

    return valid;
  }


  handleSumbit = () => {
    console.log('submitting')
    let matchData = {
      created_by: this.props.userId,
      created_at: getTimestamp(),
      events: null,
      format: this.props.season.league.format,
      league: this.props.season.league.id,
      location: {
        ground_id: this.state.formData.ground_id,
        ground_name: this.state.formData.ground_name,
        pitch_id: this.state.formData.pitch_id,
        pitch_name: this.state.formData.pitch_name,
      },
      match_day_squads: {
        away_team_squad: null,
        home_team_squad: null,
      },
      player_group: this.props.season.league.player_group,
      schedule: {
        duration: this.state.formData.duration,
        kickoff_date: this.state.formData.kickoff_date,
        kickoff_time: this.state.formData.kickoff_time,
      },
      referee: this.state.formData.referee.id,
      teams: {
        away_team: this.state.formData.away_team,
        home_team: this.state.formData.home_team
      },
      timings: {
        kicked_off_at: null,
        half_time_at: null,
        seconds_in_play_first_half: null,
        second_half_kicked_off_at: null,
        full_time_at: null,
        seconds_in_play_second_half: null,
        abandoned_at: null,
      },
      type: 'league'
    }

    this.setState({'loading': true})

    createMatch(
      matchData,
      this.props.season.league_org.id,
      this.props.season.league.id,
      this.props.season.id
    ).then(() => {
      console.log('done')
      this.props.navigate(`/league-orgs/${this.props.season.league_org.id}/leagues/${this.props.season.league.id}/seasons/${this.props.season.id}`)
    }).catch(
      () => this.props.setErrorState('Failed to create match.')
    )
  }

  
  render() {
    return (
      <div className="form">
        <div className="form-header">
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Venue:
          </div>
          <div className="form-field-container">
            <Picker
              label={'venue'}
              default={''}
              value={this.state.formData.ground_name}
              options={this.venueOptions}
              selectedLabel={this.state.selectedLabel}
              handlePickerClick={this.handlePickerClick}
              handleOptionClick={() => {this.setState({'selectedLabel': ''})}}
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Pitch:
          </div>
          <div className="form-field-container">
            <Picker
              label={'pitch'}
              default={this.pitchOptions.length > 0 ? this.pitchOptions[0].text : ''}
              value={this.state.formData.pitch_name}
              options={this.pitchOptions}
              selectedLabel={this.state.selectedLabel}
              handlePickerClick={this.handlePickerClick}
              handleOptionClick={this.handlePitchSelection}
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Duration (mins):
          </div>
          <div className="form-field-container">
            <Picker
              label={'duration'}
              default={'40'}
              value={this.state.formData.duration}
              options={this.durationOptions}
              selectedLabel={this.state.selectedLabel}
              handlePickerClick={this.handlePickerClick}
              handleOptionClick={this.handleDurationSelection}
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Home Team:
          </div>
          <div className="form-field-container">
            <Picker
              label={'homeTeam'}
              default={this.teamOptions.length > 0 ? this.teamOptions[0].text : ''}
              value={this.state.formData.home_team == '' ? '' : this.state.formData.home_team.name}
              options={this.teamOptions}
              selectedLabel={this.state.selectedLabel}
              handlePickerClick={this.handlePickerClick}
              handleOptionClick={this.handleHomeTeamSelection}
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Away Team:
          </div>
          <div className="form-field-container">
            <Picker
              label={'awayTeam'}
              default={this.teamOptions.length > 0 ? this.teamOptions[0].text : ''}
              value={this.state.formData.away_team == '' ? '' : this.state.formData.away_team.name}
              options={this.teamOptions}
              selectedLabel={this.state.selectedLabel}
              handlePickerClick={this.handlePickerClick}
              handleOptionClick={this.handleAwayTeamSelection}
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Referee:
          </div>
          <div className="form-field-container">
            <Picker
              label={'referee'}
              default={this.refereeOptions.length > 0 ? this.refereeOptions[0].text : ''}
              value={this.state.formData.referee == '' ? '' : `${this.state.formData.referee.first_name} ${this.state.formData.referee.last_name}`}
              options={this.refereeOptions}
              selectedLabel={this.state.selectedLabel}
              handlePickerClick={this.handlePickerClick}
              handleOptionClick={this.handleRefereeSelection}
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Kickoff Date:
          </div>
          <div className="form-field-container">
            <DatePicker
              className={this.state.formData.kickoff_date == '' ? "picker date-picker unconfirmed-picker-value" : "picker date-picker confirmed-picker-value"}
              dateFormat="dd/MM/yyyy"
              selected={this.state.formData.kickoff_date == '' ? new Date() : new Date(this.state.formData.kickoff_date)}
              onChange={(date) => this.handleKickoffDateSelection(date)}
            />
          </div>
        </div>

        <div className="form-field-row-container">
          <div className="form-field-label-container">
              Kickoff Time:
          </div>
          <div className="form-field-container">
            <TimePicker
              label={'kickoffTime'}
              defaultHour={'20'}
              defaultMinute={'00'}
              valueHour={this.state.kickoffTimeSelection.hour}
              valueMinute={this.state.kickoffTimeSelection.minute}
              options={this.kickoffTimeOptions}
              selectedLabel={this.state.selectedLabel}
              handlePickerClick={this.handlePickerClick}
              handleOptionClick={this.handleKickoffTimeSelection}
            />
          </div>
        </div>

        <div className="form-error-message-container">
        </div>

        <div className="form-button-container">
          <SubmitButton
            buttonText={'create'}
            className={this.validateFormData(this.state.formData) ? '_button enabled-button' : '_button disabled-button'}
            disabled={!this.validateFormData(this.state.formData)}
            handleSubmit={this.handleSumbit}
            handleDisabledSubmit={() => {console.log('button disbled')}}
          />
        </div>

        <div className="form-footer">
        </div>
        
      </div>
    );
  }
}

export default CreateMatchForm;

CreateMatchForm.propTypes = {
  season: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  setErrorState: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired
}