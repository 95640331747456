// import core objects
import React from 'react'

// import navigation functions
import { Link, useNavigate } from "react-router-dom"

// import auth context
import { useAuth } from '../../contexts/AuthContext'

// import styles
import '../../styles/main.css'
import '../../styles/auth.css'

// import components
import LogInForm from '../components/LogInForm.js'


export default function LogInPage() {
  let navigate = useNavigate()

  return (
    <div className="mainContainer">

      <div className="page-title-container">
      </div>

      <div className="form-container">
        <LogInForm
          logInFunction={useAuth().login}
          navigate={navigate}
        />
      </div>

      <div className="authSwitchText">
            Don&#39;t have an account? <Link to="/sign-up" style={{paddingLeft: '5px'}}>Sign Up</Link>
      </div>

      <div className="page-bottom-container">
      </div>

    </div>
  );
}