import React from 'react'
import PropTypes from 'prop-types'

class VenueCard extends React.Component {
  constructor(props) {
    super(props)
    this.stadiumIcon = require(`../../assets/stadium.png`)
  }

  render() {
    let numPitches = Object.keys(this.props.venue.pitches).length
    let nounString = numPitches == 1 ? 'pitch' : 'pitches'
    return (
      <div key={this.props.venue.id} className="_card">
        <div className="card-image-container">
          <img src={this.stadiumIcon} className="venue-card-image"/>
        </div>
        <div className="card-details-container">
          <div className="card-title-detail-container">
            <p>{this.props.venue.name}</p>
          </div>
          <div className="card-detail-container">
            <p>{this.props.venue.address}</p>
          </div>
          <div className="card-detail-container">
            <p>{this.props.venue.city}</p>
          </div>
          <div className="card-detail-container">
            <p>{numPitches} {nounString}</p>
          </div>
        </div>
      </div>
    );
  }
}

VenueCard.propTypes = {
  venue: PropTypes.object.isRequired
}

export default VenueCard;