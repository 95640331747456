export function sortAlphabetically(a, b) {
  const stringA = a.toLowerCase() // ignore upper and lowercase
  const stringB = b.toLowerCase() // ignore upper and lowercase
  
  if (stringA < stringB) {
    return -1;
  }

  if (stringA > stringB) {
    return 1;
  }
  
  // names must be equal
  return 0;
}