// import core objects
import React from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

export default function LogoutButton() {
  const { logout } = useAuth()
  const navigate = useNavigate()

  async function handleLogout() {
    try {
      await logout()
      navigate('/log-in')
    }
    catch {
      console.log('Failed to log out')
    }
  }

  return (
    <div className="logout-button" onClick={handleLogout}>
      Log Out
    </div>
  );
}