// import firebase db and functions
import { database } from '../firebase.js'
import { ref, onValue, push, set, update } from 'firebase/database'

// import utils
import { getTimestamp } from '../utils/dateAndTime'


export const getLeagueOrgIds = async (userId) => {
  return new Promise((resolve, reject) => {
    let dbPath = `league_organisers/${userId}/league_orgs`
    let dbRef = ref(database, dbPath)

    try {
      onValue(dbRef, (snapshot) => {
        let leagueOrgIds = snapshot.val() == null ? [] : Object.keys(snapshot.val())
        resolve(leagueOrgIds);
      })
    } catch (error) {
      reject(error);
    }
  });
}


export const getLeagueOrg = async (leagueOrgId) => {
  return new Promise((resolve, reject) => {
    let dbPath = `league_orgs/${leagueOrgId}`
    let dbRef = ref(database, dbPath)

    try {
      onValue(dbRef, (snapshot) => {
        let leagueOrg = {
          'id': leagueOrgId,
          ...snapshot.val()
        }
        resolve(leagueOrg);
      })
    } catch (error) {
      reject(error);
    }
  });
}


export const getSeason = async (leagueOrgId, leagueId, seasonId) => {
  return new Promise((resolve, reject) => {
    let dbPath = `league_orgs/${leagueOrgId}/leagues/${leagueId}/seasons/${seasonId}`
    let dbRef = ref(database, dbPath)

    try {
      onValue(dbRef, (snapshot) => {
        let season = {
          'id': seasonId,
          ...snapshot.val()
        }
        resolve(season);
      })
    } catch (error) {
      reject(error);
    }
  });
}


export const getTeam = async (teamId) => {
  return new Promise((resolve, reject) => {
    let dbPath = `teams/${teamId}`
    let dbRef = ref(database, dbPath)

    try {
      onValue(dbRef, (snapshot) => {
        let team = {
          'id': teamId,
          ...snapshot.val()
        }
        resolve(team);
      })
    } catch (error) {
      reject(error);
    }
  });
}


export const getMatch = async (matchId) => {
  return new Promise((resolve, reject) => {
    let dbPath = `games/${matchId}`
    let dbRef = ref(database, dbPath)

    try {
      onValue(dbRef, (snapshot) => {
        let match = {
          'id': matchId,
          ...snapshot.val()
        }
        resolve(match);
      })
    } catch (error) {
      reject(error);
    }
  });
}


export const getVenue = async (venueId) => {
  return new Promise((resolve, reject) => {
    let dbPath = `grounds/${venueId}`
    let dbRef = ref(database, dbPath)

    try {
      onValue(dbRef, (snapshot) => {
        let venue = {
          'id': venueId,
          ...snapshot.val()
        }
        resolve(venue);
      })
    } catch (error) {
      reject(error);
    }
  });
}


export const getPitch = async (pitchId) => {
  return new Promise((resolve, reject) => {
    let dbPath = `pitches/${pitchId}`
    let dbRef = ref(database, dbPath)

    try {
      onValue(dbRef, (snapshot) => {
        let pitch = {
          'id': pitchId,
          ...snapshot.val()
        }
        resolve(pitch);
      })
    } catch (error) {
      reject(error);
    }
  });
}


export const getUsers = async () => {
  return new Promise((resolve, reject) => {
    let dbPath = `users`
    let dbRef = ref(database, dbPath)

    try {
      onValue(dbRef, (snapshot) => {
        let users = snapshot.val()
        resolve(users);
      })
    } catch (error) {
      reject(error);
    }
  });
}


export const getRefereesData = async () => {
  return new Promise((resolve, reject) => {
    let dbPath = `referees`
    let dbRef = ref(database, dbPath)

    try {
      onValue(dbRef, (snapshot) => {
        let refereesData = snapshot.val()

        resolve(refereesData);
      })
    } catch (error) {
      reject(error);
    }
  });
}


export const getLeague = async (leagueOrgId, leagueId) => {

  let leagueOrg = await getLeagueOrg(leagueOrgId)
  let league = leagueOrg.leagues[leagueId]

  league.id = leagueId
  league.league_org_id = leagueOrg.id
  league.league_org_name = leagueOrg.name
  league.league_org_logo_file = leagueOrg.logo_file
  let venue = await getVenue(league.ground)
  league.venue = venue

  return league;
}


export const getLeagues = async (leagueOrgIds) => {
  let leagues = []

  for (let leagueOrgId of leagueOrgIds) {
    let leagueOrg = await getLeagueOrg(leagueOrgId)

    if (leagueOrg?.leagues != undefined) {
      for (let leagueId of Object.keys(leagueOrg.leagues)) {
        leagueOrg.leagues[leagueId].id = leagueId
        leagueOrg.leagues[leagueId].league_org_id = leagueOrg.id
        leagueOrg.leagues[leagueId].league_org_name = leagueOrg.name
        leagueOrg.leagues[leagueId].league_org_logo_file = leagueOrg.logo_file
        let venue = await getVenue(leagueOrg.leagues[leagueId].ground)
        leagueOrg.leagues[leagueId].venue = venue
        leagues.push(leagueOrg.leagues[leagueId])
      }
    }
  }

  return leagues;
}


export const getReferees = async () => {
  let users = await getUsers()
  let refereesData = await getRefereesData()
  let referees = []

  for (let refereeId of Object.keys(refereesData)) {
    let referee = {'id': refereeId, ...refereesData[refereeId]}
    for (let key of Object.keys(users[refereeId])) {
      referee[key] = users[refereeId][key]
    }
    referees.push(referee)
  }

  return referees;
}


export const getSeasonDetailed = async (leagueOrgId, leagueId, seasonId) => {
  let leagueOrg = await getLeagueOrg(leagueOrgId)
  let season = await getSeason(leagueOrgId, leagueId, seasonId)
  season.league = {'id': leagueId, ...leagueOrg.leagues[leagueId]}
  season.league_org = leagueOrg

  //add venue details
  let venue = await getVenue(season.league.ground)
  if (venue?.pitches != undefined) {
    for (let pitchId of Object.keys(venue.pitches)) {
      venue.pitches[pitchId] = await getPitch(pitchId)
    }
  }
  season.venue = venue

  //add teams
  let teams = []

  if (season?.teams != undefined) {
    for (let teamId of Object.keys(season.teams)) {
      let team = await getTeam(teamId)
      teams.push(team)
    }
  }
  season.teams = teams

  //add matches
  let matches = []

  if (season?.games != undefined) {
    for (let matchId of Object.keys(season.games)) {
      let match = await getMatch(matchId)
      matches.push(match)
    }
  }
  season.games = matches

  // add referees
  season.referees = await getReferees()

  return season;
}


export const getLeagueOrgs = async (userId) => {
  // get league org ids
  let leagueOrgIds = await getLeagueOrgIds(userId)

  // get league orgs
  let leagueOrgs = []
  for (let leagueOrgId of leagueOrgIds) {
    let leagueOrg = await getLeagueOrg(leagueOrgId)
    leagueOrgs.push(leagueOrg)
  }

  return leagueOrgs;
}


export const getVenues = async () => {
  let request = new Promise((resolve, reject) => {
    let dbPath = `grounds`
    let dbRef = ref(database, dbPath)

    try {
      onValue(dbRef, (snapshot) => {
        let venuesData = snapshot.val()
        let venues = []

        for (let venueId of Object.keys(venuesData)) {
          let venue = {
            'id': venueId,
            ...venuesData[venueId]
          }
          venues.push(venue)
        }
        
        resolve(venues);
      })
    } catch (error) {
      reject(error);
    }
  });

  let venues = await request

  return venues;
}


export const addUserData = async (userId, userData) => {
  // add user data to db
  let data = {
    'created_at': getTimestamp(),
    'first_name': userData.first_name,
    'last_name': userData.last_name,
    'gender': userData.gender,
    'dob': userData.dob,
    'email': userData.email
  }
  let dbPath = `users/${userId}`
  let dbRef = ref(database, dbPath)
  await set(dbRef, data)

  // add league organiser profile
  data = {
    'league_orgs': null
  }
  dbPath = `league_organisers/${userId}`
  dbRef = ref(database, dbPath)
  await set(dbRef, data)

  // add player profile
  data = {
    games: null,
    teams: null,
    stats: {
      position: 'CM',
      position_type: 'midfielder',
      rating: '55',
      description: 'novice',
      games_played: 0,
      games_won: 0,
      games_drawn: 0,
      games_lost: 0,
      goals: 0,
      assists: 0,
      clean_sheets: 0
    }
  }
  dbPath = `players/${userId}`
  dbRef = ref(database, dbPath)
  await set(dbRef, data)

  // add referee profile
  data = {
    'games': null,
    'ratings': null
  }
  dbPath = `referees/${userId}`
  dbRef = ref(database, dbPath)
  await set(dbRef, data)
}


export const createMatch = async (matchData, leagueOrgId, leagueId, seasonId) => {
  // create match
  let dbPath = `games`
  let dbRef = ref(database, dbPath)
  let matchDbRef = await push(dbRef, matchData)
  const matchId = matchDbRef._path.pieces_[matchDbRef._path.pieces_.length - 1]

  // add match to season
  let season = await getSeason(leagueOrgId, leagueId, seasonId)
  let matches = season?.games == null ? {} : season.games
  matches[matchId] = true
  dbPath = `league_orgs/${leagueOrgId}/leagues/${leagueId}/seasons/${seasonId}/games`
  dbRef = ref(database, dbPath)
  await update(dbRef, matches)

  // add match to teams' matches
  for (let teamId of [matchData.teams.home_team.id, matchData.teams.away_team.id]) {
    let team = await getTeam(teamId)
    matches = team?.games == undefined ? {} : team.games
    matches[matchId] = true
    let dbPath = `teams/${teamId}/games`
    let dbRef = ref(database, dbPath)
    await update(dbRef, matches)
  }

  // add match to referee's matches
  let refereeData = await getRefereesData()
  refereeData = refereeData[matchData.referee]
  matches = refereeData?.games == null ? {} : refereeData.games
  matches[matchId] = true
  dbPath = `referees/${matchData.referee}/games`
  dbRef = ref(database, dbPath)
  await update(dbRef, matches)

  return matchId;
}