//function to sort rows in table data
let sortTableRows = (a, b) => {
  if (a.points != b.points) {
    return b.points - a.points;
  }
  else if (a.goalDifference != b.goalDifference) {
    return a.goalDifference - b.goalDifference;
  }
  else {
    if (a.team.toLowerCase < b.team.toLowerCase) {
      return -1;
    }
    else if (a.team.toLowerCase > b.team.toLowerCase) {
      return 1;
    }
    else {
      return 0;
    }
  }
}

export const createTable = (teams, matches) => {

  var tableData = []
  for (let team of teams) {
    var row = {
      team: team.team_name,
      crestFile: team.crest_file,
      played: 0,
      won: 0,
      drawn: 0,
      lost: 0,
      goalDifference: 0,
      points: 0
    }

    //get goals from leage matches
    for (let match of matches) {

      //TODO exclude postponed and abandoned matches

      if (match.data.teams.home_team.id == team.id || match.data.teams.away_team.id == team.id) {
        row.played += 1
        var [goalsFor, goalsAgainst] = [0, 0]

        if (match.data?.events != undefined) {
          for (let event of Object.values(match.data.events)) {
            if (event.type == 'goal') {
              if (event.team_id == team.id) {
                goalsFor += 1
              }
              else {
                goalsAgainst += 1
              }
            }
          }
        }

        //update tallies
        if (goalsFor > goalsAgainst) {
          row.won += 1
        }
        else if (goalsFor < goalsAgainst) {
          row.lost += 1
        }
        else {
          row.drawn += 1
        }

        row.goalDifference += (goalsFor - goalsAgainst)
        row.points = 3 * row.won + 1 * row.drawn + 0 * row.lost
      }
    }
    tableData.push(row)
  }

  //sort table data and rank rows in table data
  tableData.sort((a, b) => sortTableRows(a,b))

  for (let i = 0; i < tableData.length; i++) {
    tableData[i].rank = i + 1
  }

  return tableData;
}