// import core objects
import React, { useState, useEffect } from 'react'
import { Alert, Spinner } from 'react-bootstrap'

// import auth context
//import { useAuth } from '../../contexts/AuthContext'

// import navigation functions
import { useNavigate } from "react-router-dom";

// import actions
import { getLeagueOrgs, getLeagues } from '../../actions/actions.js'

// import styles
import '../../styles/main.css'
import '../../styles/nav.css'
import '../../styles/components.css'

// import components
import Navbar from '../components/Navbar'
import Breadcrumbs from '../components/Breadcrumbs'
import LeagueCard from '../components/LeagueCard'


export default function LeaguesPage() {
  const [loadingState, setLoadingState] = useState(true)
  const [errorState, setErrorState] = useState(false)
  const [leaguesState, setLeaguesState] = useState([])
  //const userId = useAuth().currentUser.uid
  const userId = 'b8oHsrovTHX6KoNBIkS1hdteCgu1'
  let navigate = useNavigate()
  const crumbs = [
    {crumb: 'leagues', to: '/'}
  ]


  useEffect(() => {
    // get league orgs
    getLeagueOrgs(userId).then(leagueOrgs => {
      // get leagues
      let leagueOrgIds = []

      for (let leagueOrg of leagueOrgs) {
        leagueOrgIds.push(leagueOrg.id)
      }

      return leagueOrgIds;
    }).then(leagueOrgIds => {
      let leagues = getLeagues(leagueOrgIds)

      return leagues;
    }).then(leagues => {
      setLeaguesState(leagues)
    }).catch(
      () => setErrorState('Failed to fetch leagues.')
    ).finally(
      () => setLoadingState(false)
    )
  }, [])


  let mapLeagues = (leagues) => {
    return leagues.map((league) => {
      return (
        <LeagueCard key={league.id} league={league} navigate={navigate}/>
      );
    });
  }


  let displayLeagues = (leagues) => {
    // show spinner if loading
    if (loadingState) {
      return (
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    }

    // display text if no results
    if (leagues.length == 0) {
      return (
        <div className="card-container">
          <div className="no-results-container">
            No leagues to display.
          </div>
        </div>
      );
    }

    // else map results
    return (
      <div className="card-container">
        {mapLeagues(leagues)}
      </div>
    );
  }


  return (
    <div className="mainContainer">
      <Navbar/>
      
      <div className="page-title-container">
          Leagues
      </div>

      <Breadcrumbs crumbs={crumbs}/>

      {errorState && <Alert variant="danger">{errorState}</Alert>}
      
      {displayLeagues(leaguesState)}

      <div className="page-bottom-container">
      </div>

    </div>
  );
}