// import core objects
import React, { useState, useEffect } from 'react'
import { Alert, Spinner } from 'react-bootstrap'

// import navigation functions
import { useNavigate, useParams } from "react-router-dom"

// import auth context
// import { useAuth } from '../../contexts/AuthContext'

// import actions
import { getSeasonDetailed } from '../../actions/actions.js'

// import utility functions
import { formatDbDate } from '../../utils/dateAndTime.js'

// import styles
import '../../styles/main.css'
import '../../styles/nav.css'

// import components
import Navbar from '../components/Navbar'
import Breadcrumbs from '../components/Breadcrumbs'
import CreateMatchForm from '../components/CreateMatchForm.js'


export default function CreateMatchPage() {
  const [loadingState, setLoadingState] = useState(true)
  const [errorState, setErrorState] = useState(false)
  const [seasonState, setSeasonState] = useState(undefined)
  const { leagueOrgId, leagueId, seasonId } = useParams()
  const [matchState, setMatchState] = useState(undefined)
  const [crumbs, setCrumbs] = useState([])
  // const userId = useAuth().currentUser.uid
  const userId = 'b8oHsrovTHX6KoNBIkS1hdteCgu1'
  let navigate = useNavigate()


  useEffect(() => {
    // get season
    getSeasonDetailed(leagueOrgId, leagueId, seasonId).then(season => {

      setCrumbs([
        {crumb: 'leagues', to: '/'},
        {crumb: `${season.league_org.name}`, to: `/league-orgs`},
        {crumb: `${season.league.name}`, to: `/league-orgs/${leagueOrgId}/leagues/${leagueId}`},
        {crumb: `${formatDbDate(season.start_date)} - ${formatDbDate(season.end_date)}`, to: `/league-orgs/${leagueOrgId}/leagues/${leagueId}/seasons/${seasonId}`}
      ])

      let matchData = {
        events: null,
        format: season.league.format,
        league: leagueId,
        location: {
          ground_id: season.venue.id,
          ground_name: season.venue.name,
          pitch_id: undefined,
          pitch_name: undefined
        },
        match_day_squads: {
          away_team_squad: null,
          home_team_squad: null
        },
        player_group: season.league.player_group,
        referee: undefined,
        schedule: {
          duration: undefined,
          kickoff_date: undefined,
          kickoff_time: undefined
        },
        teams: {
          away_team: undefined,
          home_team: undefined
        },
        timings: {
          abandoned_at: null,
          full_time_at: null,
          half_time_at:  null,
          kicked_off_at: null,
          second_half_kicked_off_at: null,
          seconds_in_play_first_half: null,
          seconds_in_play_second_half: null
        },
        type: 'league match'
      }

      setSeasonState(season)
      setMatchState(matchData)
    }).catch(
      () => setErrorState('Failed to fetch season.')
    ).finally(
      () => setLoadingState(false)
    )
  }, [])


  let displayCreateForm = () => {

    // show spinner if loading
    if (loadingState || seasonState == undefined || matchState == undefined) {
      return (
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    }
    
    return (
      <div className="form-container">
        <CreateMatchForm season={seasonState} userId={userId} setErrorState={setErrorState} navigate={navigate}/>
      </div>
    );
  }


  return (
    <div className="mainContainer">
      <Navbar/>
      
      <div className="page-title-container">
        {seasonState && seasonState.league.name}
      </div>

      <Breadcrumbs crumbs={crumbs}/>

      {errorState && <Alert variant="danger">{errorState}</Alert>}

      <div className="page-subtitle-container">
        Create Match
      </div>

      {displayCreateForm()}

      <div className="page-bottom-container">
      </div>

    </div>
  );
}